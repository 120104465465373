import { Controller } from "stimulus";
import { debounce } from "lodash";

export default class extends Controller {
  static targets = ["field", "results", "selectedList", "selectedHeader", "hiddenField"];
  static values = { autocompleteUrl: String };

  connect() {
    this.selectedItems = [];
    this.highlightedIndex = -1;
    this.resultsTarget.style.display = 'none';
    this.selectedHeaderTarget.style.display = 'none';
    this.debouncedFetchResults = debounce(this.fetchResults.bind(this), 300);
    this.fieldTarget.addEventListener('input', this.onInput.bind(this));
    this.fieldTarget.addEventListener('keydown', this.onKeyDown.bind(this));
    this.populateExistingSelections()
  }

  populateExistingSelections() {
    const cachedItems = this.hiddenFieldTarget.value
    if (cachedItems) {
      const items = JSON.parse(cachedItems);
      items.forEach(item => {
        this.addItem(item);
      });
      this.hiddenFieldTarget.value = '';
    }
  }

  onInput(event) {
    const term = event.target.value;
    if (term.length > 0) {
      this.debouncedFetchResults(term);
    } else {
      this.clearResults();
    }
  }

  fetchResults(term) {
    const separator = this.autocompleteUrlValue.includes('?') ? '&' : '?';
    fetch(`${this.autocompleteUrlValue}${separator}query=${term}`)
      .then(response => response.json())
      .then(data => this.showResults(data));
  }

  onKeyDown(event) {
    if (event.key === 'ArrowDown') {
      this.highlightNext();
    } else if (event.key === 'ArrowUp') {
      this.highlightPrevious();
    } else if (event.key === 'Enter') {
      event.preventDefault();
      this.selectHighlighted();
    }
  }

  showResults(items) {
    this.clearResults();
    this.positionResults();

    this.highlightedItems = [];
    items.forEach((item, index) => {
      const listItem = document.createElement('li');
      const link = document.createElement('a');
      link.tabIndex = -1;
      link.dataset.index = index;
      link.innerHTML = `${this.getItemIcon(item.type)} ${item.label}`;
      if (item.type !== 'Error' && item.type !== 'Nothing') {
        link.addEventListener('click', () => this.addItem(item));
        link.addEventListener('mouseover', () => this.highlightItem(index));
      }
      listItem.appendChild(link);
      this.resultsTarget.appendChild(listItem);
      this.highlightedItems[index] = item;
    });
  }

  positionResults() {
    this.resultsTarget.style.display = 'block';
    this.resultsTarget.style.top = `${this.fieldTarget.offsetTop + this.fieldTarget.offsetHeight}px`;
    this.resultsTarget.style.left = `${this.fieldTarget.offsetLeft}px`;
    this.resultsTarget.style.width = `${this.fieldTarget.offsetWidth}px`;
  }

  clearResults() {
    this.resultsTarget.innerHTML = '';
    this.resultsTarget.style.display = 'none';
    this.highlightedIndex = -1;
  }

  highlightNext() {
    if (this.highlightedIndex < this.resultsTarget.children.length - 1) {
      this.highlightItem(this.highlightedIndex + 1);
    }
  }

  highlightPrevious() {
    if (this.highlightedIndex > 0) {
      this.highlightItem(this.highlightedIndex - 1);
    }
  }

  highlightItem(index) {
    if (this.highlightedIndex >= 0) {
      this.resultsTarget.children[this.highlightedIndex].classList.remove('highlighted');
    }
    this.highlightedIndex = index;
    this.resultsTarget.children[this.highlightedIndex].classList.add('highlighted');
  }

  selectHighlighted() {
    if (this.highlightedIndex >= 0) {
      const itemIndex = this.resultsTarget.children[this.highlightedIndex].querySelector('a').dataset.index;
      const item = this.highlightedItems[itemIndex];
      this.addItem(item);
    }
  }

  addItem(item) {
    const userDiv = document.createElement('div');
    userDiv.className = 'user';
    userDiv.innerHTML = `
      ${item.label}
      <input type="hidden" name="${this.hiddenFieldTarget.name}" value="${item.value}">
      <i class="fa fa-times" data-action="click->components--user-picker#removeItem" data-value="${item.value}"></i>
    `;
    this.selectedListTarget.appendChild(userDiv);
    this.selectedItems.push(item.value);
    this.clearResults();
    this.fieldTarget.value = '';
    this.fieldTarget.focus();
    this.updateSelectedHeader();
  }

  removeItem(event) {
    const value = event.target.dataset.value;
    this.selectedItems = this.selectedItems.filter(item => item !== value);
    event.target.closest('.user').remove();
    this.updateSelectedHeader();
  }

  updateSelectedHeader() {
    if (this.selectedItems.length > 0) {
      this.selectedHeaderTarget.style.display = 'block';
    } else {
      this.selectedHeaderTarget.style.display = 'none';
    }
  }

  getItemIcon(type) {
    switch (type) {
      case 'User':
        return '<i class="fa fa-user fa-fw"></i> ';
      case 'Error':
        return '<i class="fa fa-exclamation-triangle fa-fw"></i> ';
      case 'Nothing':
        return '<i class="fa fa-minus-circle fa-fw"></i> ';
      default:
        return '';
    }
  }
}
