import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["searchInput", "clearFilterSection", "clearFilterButton"]

  connect() {
    this.checkFilters();
    this.advanceClearFilter();

    document.addEventListener('search:results-loaded', this.checkFilters.bind(this));
  }

  disconnect() {
    document.removeEventListener('search:results-loaded', this.checkFilters.bind(this));
  }

  advanceClearFilter() {
    if (!this.hasClearFilterButtonTarget && !this.hasSearchInputTarget) {
      return;
    }

    const url = new URL(this.clearFilterButtonTarget.getAttribute('href'), window.location.origin);
    url.searchParams.set('q', this.searchInputTarget.value);

    this.clearFilterButtonTarget.setAttribute('href', url.toString());
  }

  checkFilters() {
    const selects = this.element.querySelectorAll('select');
    let anySelected = Array.from(selects).some(select => select.value !== '');

    if (anySelected) {
      this.clearFilterSectionTarget.classList.remove('hide');
    } else {
      this.clearFilterSectionTarget.classList.add('hide');
    }
  }

  clearFilters() {
    const selects = this.element.querySelectorAll('select');
    selects.forEach(select => {
      select.value = '';
    });
  }
}
