import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "completed", "completionLink" ]

  connect() {
    if (this.hasCompletionLinkTarget) {
      this.completionLinkTarget.setAttribute("disabled", true);
    }
  }

  completedTargetConnected() {
    if (this.hasCompletionLinkTarget) {
      this.completionLinkTarget.removeAttribute("disabled");
    }
  }
}
