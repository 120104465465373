import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static outlets = ["shared--button-dropdown"]

  static targets = ['dropdownContent', 'dropdownBackground']

  static classes = ['mobile-dropdown']

  static values = {
    open: Boolean
  }

  toggle(e) {
    const mobileToggle = document.querySelector('.mobile-menu-toggle')

    if (this.isTouchDevice() || !this.isDisplayNone(mobileToggle)) {
      if (e.type === 'click') {
        e.preventDefault();
        this.toggleDropdown();
      }
    } else if (!this.isTouchDevice() && this.openValue === false) {
      e.preventDefault();
      this.toggleDropdown();
    }
  }

  isTouchDevice() {
    return ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
  }

  isDisplayNone(element) {
    const style = window.getComputedStyle(element);
    return style.display === 'none';
  }

  toggleDropdown() {
    if (this.openValue == true) {
      this.hide();
    } else {
      this.show();
    }
  }

  hide() {
    this.openValue = false
    this.dropdownContentTarget.classList.add('hide');
    this.dropdownBackgroundTarget.classList.add('hide');
  }

  show() {
    this.sharedButtonDropdownOutlets.forEach(dropdown => {
      dropdown.hide()
    })
    this.openValue = true
    this.dropdownContentTarget.classList.remove('hide');
    this.dropdownBackgroundTarget.classList.remove('hide');
  }
}
