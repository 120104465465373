// This class is for use on drag and drop fields for files and can be instantiated from stimulus controllers

export class DragDropUpload {
  constructor(element, callback) {
    this.dragDropElement = element;
    this.callback = callback;
    this.attachEvents();
  }

  attachEvents() {
    this.dragDropElement.querySelectorAll('.drag-drop-upload').forEach(upload => {
      const input = upload.querySelector('input[type="file"]');

      input.addEventListener('change', (e) => {
        this.fileSelected(upload, e.target.files);
      });

      ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(eventType => {
        upload.addEventListener(eventType, (e) => {
          e.preventDefault();
          e.stopPropagation();
        });
      });

      upload.addEventListener('dragover', () => upload.classList.add('is-dragover'));
      upload.addEventListener('dragenter', () => upload.classList.add('is-dragover'));

      ['dragleave', 'dragend', 'drop'].forEach(eventType => {
        upload.addEventListener(eventType, () => upload.classList.remove('is-dragover'));
      });

      upload.addEventListener('drop', (e) => {
        const files = e.dataTransfer.files;
        this.fileSelected(upload, files);
      });
    });

    this.dragDropElement.querySelector('.upload-btn').addEventListener('click', () => {
      this.dragDropElement.querySelector('.drag-drop-upload input').click();
    });
  }

  fileSelected(upload, files) {
    this.callback(files);
  }
}
